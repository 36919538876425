import React from "react"
import video from "../assets/monofocus-promo.mp4"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Button from "./button"

import "./monofocus.scss"

export default () => (
  <div className="monofocus-background">
    <div className="monofocus-wrapper">
      <div className="monofocus__crop">
        <div className="monofocus">
          <div className="monofocus__box">
            <div className="monofocus__lead">BROUGHT TO YOU BY:</div>
            <div className="monofocus__title">MonoFocus</div>
            <div className="monofocus__subtitle">
              Focus on one task at a time.
            </div>
            <div className="monofocus__description">
              Menu bar app that reminds you what you should be working on.
            </div>
            <Button
              tag={OutboundLink}
              href="https://monofocus.app/?utm_source=1440&utm_medium=landing&utm_campaign=monofocus_section"
              type="secondary"
              className="monofocus__cta"
            >
              Get beta for free
            </Button>
          </div>

          <div className="monofocus__movie">
            <video className="movie" src={video} autoPlay muted loop />
          </div>
        </div>
      </div>
    </div>
  </div>
)
