import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import "./footer.scss"

const year = () => {
  const date = new Date()
  return date.getFullYear()
}

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: "" }
  }

  componentDidMount() {
    this.setState({ email: "hello" + "@" + "monofocus.app" })
  }

  render() {
    return (
      <footer className="footer">
        <div className="footer__item">
          Brought to you by{" "}
          <OutboundLink
            href="https://monofocus.app/?utm_source=1440&utm_medium=landing&utm_campaign=footer"
            className="footer__link"
          >
            MonoFocus
          </OutboundLink>
        </div>
        <div className="footer__separator">•</div>
        <div className="footer__item">
          <Link to="/privacy-policy" className="footer__link">
            Privacy Policy
          </Link>
        </div>
        <div className="footer__separator">•</div>
        <div className="footer__item">
          <a
            href="https://www.dropbox.com/s/c1jwu0wey7va79d/PressKit.zip?dl=1"
            className="footer__link"
          >
            Press Kit
          </a>
        </div>
        <div className="footer__separator">•</div>
        <div className="footer__item">
          <a href={"mailto:" + this.state.email} className="footer__link">
            Contact Us
          </a>
        </div>
      </footer>
    )
  }
}
