import React from "react"

import "./button.scss"

export default ({ tag = "a", type, className, children, ...props }) =>
  React.createElement(
    tag,
    { className: `button button--${type} ${className}`, ...props },
    children
  )
