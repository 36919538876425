import React from "react"
import "./product-hunt-badge.scss"

const ProductHuntBadge = () => (
  <a
    className="product-hunt-badge"
    href="https://www.producthunt.com/posts/1440-minutes-left-today?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-1440-minutes-left-today"
    target="_blank"
  >
    <img
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=172234&theme=light"
      alt="1440 Minutes Left Today - See how many minutes you have left today, right in menubar ⏰ | Product Hunt Embed"
      style={{ width: "250px", height: "54px" }}
      width="250px"
      height="54px"
    />
  </a>
)

export default ProductHuntBadge
