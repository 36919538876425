import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import AppStoreButton from "../assets/download_on_app_store.svg"
import AppIcon from "../assets/app_icon.png"

import "./hero.scss"

export default () => (
  <main className="hero">
    <div className="hero__main">
      <div className="hero__heading">
        Make every minute <span className="hero__heading--accent">count</span>.
      </div>

      <div className="hero__description">
        <span className="hero__description--highlight">1440 minutes.</span>
        <br />
        Seems like a little, but that’s a whole day. <br />
        <br />
        Keep a track of how many minutes you have left until the day is over,
        {` `}
        <span className="hero__description--highlight">
          right in your menu bar
        </span>
        .
      </div>
    </div>
    <div className="hero__aside">
      <img className="hero__app-icon" src={AppIcon} />
      <OutboundLink
        className="hero__button"
        href="https://apps.apple.com/us/app/1440/id1483764819"
      >
        <img className="hero__button-image" src={AppStoreButton} />
      </OutboundLink>

      <div className="hero__button-subtitle">macOS 10.12+ required.</div>
    </div>
  </main>
)
