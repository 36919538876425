import React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/header"
import Hero from "../components/hero"
import Monofocus from "../components/monofocus"
import Footer from "../components/footer"
import ProductHuntBadge from "../components/product-hunt-badge"

import "normalize.css"
import "inter-ui"

import "./index.scss"

export default () => (
  <div>
    <Helmet>
      <title>1440 for macOS - Make Every Minute Count</title>
      <meta
        name="description"
        content="Keep a track of how many minutes you have left until the day is over, right in your Mac menu bar."
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="1440 for macOS - Make Every Minute Count"
      />
      <meta
        name="twitter:description"
        content="Keep a track of how many minutes you have left until the day is over, right in your Mac menu bar."
      />
      <meta name="twitter:image" content="https://1440app.com/og_image.png" />

      <meta property="og:image" content="https://1440app.com/og_image.png" />

      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/icons/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/icons/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/icons/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/icons/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/icons/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/icons/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/icons/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/icons/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/icons/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/icons/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/icons/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/icons/favicon-16x16.png"
      />
    </Helmet>

    <Header />
    <Hero />
    <Monofocus />
    <Footer />
    <ProductHuntBadge />
  </div>
)
